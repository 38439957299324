/**
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import React from 'react';
import { styled } from '@superset-ui/core';
import cls from 'classnames';
import Loader from 'src/assets/images/loading.gif';
import { ThreeDots, Oval } from 'react-loader-spinner';

// Add TypeScript interface for the global lottie object
declare global {
  interface Window {
    lottie: any;
  }
}

export type PositionOption =
  | 'floating'
  | 'inline'
  | 'inline-centered'
  | 'normal';
export interface Props {
  position?: PositionOption;
  className?: string;
  image?: string;
}

const LoaderImg = styled.img`
  z-index: 99;
  width: 50px;
  height: unset;
  position: relative;
  margin: 10px;
  &.inline {
    margin: 0px;
    width: 30px;
  }
  &.inline-centered {
    margin: 0 auto;
    width: 30px;
    display: block;
  }
  &.floating {
    padding: 0;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
export default function Loading({
  position = 'floating',
  image,
  className,
}: Props) {
  const wrapperStyle = {
    zIndex: 99,
    width: position === 'inline' || position === 'inline-centered' ? '50px' : '80px',
    height: 'unset',
    position: position === 'floating' ? 'absolute' : 'relative',
    margin: position === 'inline' || position === 'inline-centered' ? '0px' : '10px',
    left: position === 'floating' ? '50%' : undefined,
    top: position === 'floating' ? '50%' : undefined,
    transform: position === 'floating' ? 'translate(-50%, -50%)' : undefined,
  };

  return (
    <ThreeDots
      height="20"
      width="60"
      radius="9"
      color="#244C5A"
      ariaLabel="three-dots-loading"
      wrapperStyle={wrapperStyle}
      wrapperClass={cls('loading', position, className)}
    />
  );
}

export function LoadingHighLevel({ position = 'floating', className }: Props) {
  // Create a ref to hold the animation instance
  const animationContainer = React.useRef<HTMLDivElement>(null);
  const [animationLoaded, setAnimationLoaded] = React.useState(false);

  // Animation data inline to avoid path issues
  const animationData = React.useMemo(() => ({
    "nm":"Loader 1","ddd":0,"h":1080,"w":1080,"meta":{"g":"LottieFiles AE "},
    "layers":[
      {"ty":4,"nm":"Shape 3","sr":1,"st":0,"op":120,"ip":0,"hd":false,"ddd":0,"bm":0,"hasMask":false,"ao":0,"ks":{"a":{"a":0,"k":[-374,-190,0],"ix":1},"s":{"a":0,"k":[100,100,100],"ix":6},"sk":{"a":0,"k":0},"p":{"a":1,"k":[{"o":{"x":0.333,"y":0},"i":{"x":0.667,"y":1},"s":[446,642,0],"t":10,"ti":[0,33.333,0],"to":[0,-33.333,0]},{"o":{"x":0.167,"y":0.167},"i":{"x":0.833,"y":0.833},"s":[446,442,0],"t":15,"ti":[0,0,0],"to":[0,0,0]},{"o":{"x":0.333,"y":0},"i":{"x":0.667,"y":1},"s":[446,442,0],"t":25,"ti":[-33.333,0,0],"to":[33.333,0,0]},{"o":{"x":0.167,"y":0.167},"i":{"x":0.833,"y":0.833},"s":[646,442,0],"t":30,"ti":[0,0,0],"to":[0,0,0]},{"o":{"x":0.333,"y":0},"i":{"x":0.667,"y":1},"s":[646,442,0],"t":40,"ti":[0,-33.333,0],"to":[0,33.333,0]},{"o":{"x":0.167,"y":0.167},"i":{"x":0.833,"y":0.833},"s":[646,642,0],"t":45,"ti":[0,0,0],"to":[0,0,0]},{"o":{"x":0.333,"y":0},"i":{"x":0.667,"y":1},"s":[646,642,0],"t":55,"ti":[33.333,0,0],"to":[-33.333,0,0]},{"s":[446,642,0],"t":60}],"ix":2},"r":{"a":0,"k":0,"ix":10},"sa":{"a":0,"k":0},"o":{"a":0,"k":100,"ix":11}},"ef":[],"shapes":[{"ty":"gr","bm":0,"hd":false,"mn":"ADBE Vector Group","nm":"Rectangle 1","ix":1,"cix":2,"np":3,"it":[{"ty":"rc","bm":0,"hd":false,"mn":"ADBE Vector Shape - Rect","nm":"Rectangle Path 1","d":1,"p":{"a":0,"k":[0,0],"ix":3},"r":{"a":0,"k":20,"ix":4},"s":{"a":0,"k":[164,164],"ix":2}},{"ty":"fl","bm":0,"hd":false,"mn":"ADBE Vector Graphic - Fill","nm":"Fill 1","c":{"a":0,"k":[0.9216,0,0.2706],"ix":4},"r":1,"o":{"a":0,"k":100,"ix":5}},{"ty":"tr","a":{"a":0,"k":[0,0],"ix":1},"s":{"a":0,"k":[100,100],"ix":3},"sk":{"a":0,"k":0,"ix":4},"p":{"a":0,"k":[-374,-190],"ix":2},"r":{"a":0,"k":0,"ix":6},"sa":{"a":0,"k":0,"ix":5},"o":{"a":0,"k":100,"ix":7}}]}],"ind":1},
      {"ty":4,"nm":"Shape 2","sr":1,"st":0,"op":120,"ip":0,"hd":false,"ddd":0,"bm":0,"hasMask":false,"ao":0,"ks":{"a":{"a":0,"k":[-534,-230,0],"ix":1},"s":{"a":0,"k":[100,100,100],"ix":6},"sk":{"a":0,"k":0},"p":{"a":1,"k":[{"o":{"x":0.333,"y":0},"i":{"x":0.667,"y":1},"s":[650,442,0],"t":0,"ti":[0,-33.333,0],"to":[0,33.333,0]},{"o":{"x":0.167,"y":0.167},"i":{"x":0.833,"y":0.833},"s":[650,642,0],"t":5,"ti":[0,0,0],"to":[0,0,0]},{"o":{"x":0.333,"y":0},"i":{"x":0.667,"y":1},"s":[650,642,0],"t":15,"ti":[34,0,0],"to":[-34,0,0]},{"o":{"x":0.167,"y":0.167},"i":{"x":0.833,"y":0.833},"s":[446,642,0],"t":20,"ti":[0,0,0],"to":[0,0,0]},{"o":{"x":0.333,"y":0},"i":{"x":0.667,"y":1},"s":[446,642,0],"t":30,"ti":[0,33.333,0],"to":[0,-33.333,0]},{"o":{"x":0.167,"y":0.167},"i":{"x":0.833,"y":0.833},"s":[446,442,0],"t":35,"ti":[0,0,0],"to":[0,0,0]},{"o":{"x":0.333,"y":0},"i":{"x":0.667,"y":1},"s":[446,442,0],"t":45,"ti":[-34,0,0],"to":[34,0,0]},{"s":[650,442,0],"t":50}],"ix":2},"r":{"a":0,"k":0,"ix":10},"sa":{"a":0,"k":0},"o":{"a":0,"k":100,"ix":11}},"ef":[],"shapes":[{"ty":"gr","bm":0,"hd":false,"mn":"ADBE Vector Group","nm":"Rectangle 1","ix":1,"cix":2,"np":3,"it":[{"ty":"rc","bm":0,"hd":false,"mn":"ADBE Vector Shape - Rect","nm":"Rectangle Path 1","d":1,"p":{"a":0,"k":[0,0],"ix":3},"r":{"a":0,"k":20,"ix":4},"s":{"a":0,"k":[164,164],"ix":2}},{"ty":"fl","bm":0,"hd":false,"mn":"ADBE Vector Graphic - Fill","nm":"Fill 1","c":{"a":0,"k":[0.1412,0.298,0.3529],"ix":4},"r":1,"o":{"a":0,"k":100,"ix":5}},{"ty":"tr","a":{"a":0,"k":[0,0],"ix":1},"s":{"a":0,"k":[100,100],"ix":3},"sk":{"a":0,"k":0,"ix":4},"p":{"a":0,"k":[-534,-230],"ix":2},"r":{"a":0,"k":0,"ix":6},"sa":{"a":0,"k":0,"ix":5},"o":{"a":0,"k":100,"ix":7}}]}],"ind":2},
      {"ty":4,"nm":"Shape 1","sr":1,"st":0,"op":120,"ip":0,"hd":false,"ddd":0,"bm":0,"hasMask":false,"ao":0,"ks":{"a":{"a":0,"k":[-534,-230,0],"ix":1},"s":{"a":0,"k":[100,100,100],"ix":6},"sk":{"a":0,"k":0},"p":{"a":1,"k":[{"o":{"x":0.333,"y":0},"i":{"x":0.667,"y":1},"s":[446,442,0],"t":5,"ti":[-33.333,0,0],"to":[33.333,0,0]},{"o":{"x":0.167,"y":0.167},"i":{"x":0.833,"y":0.833},"s":[646,442,0],"t":10,"ti":[0,0,0],"to":[0,0,0]},{"o":{"x":0.333,"y":0},"i":{"x":0.667,"y":1},"s":[646,442,0],"t":20,"ti":[0,-33.333,0],"to":[0,33.333,0]},{"o":{"x":0.167,"y":0.167},"i":{"x":0.833,"y":0.833},"s":[646,642,0],"t":25,"ti":[0,0,0],"to":[0,0,0]},{"o":{"x":0.333,"y":0},"i":{"x":0.667,"y":1},"s":[646,642,0],"t":35,"ti":[33.333,0,0],"to":[-33.333,0,0]},{"o":{"x":0.167,"y":0.167},"i":{"x":0.833,"y":0.833},"s":[446,642,0],"t":40,"ti":[0,0,0],"to":[0,0,0]},{"o":{"x":0.333,"y":0},"i":{"x":0.667,"y":1},"s":[446,642,0],"t":50,"ti":[0,33.333,0],"to":[0,-33.333,0]},{"s":[446,442,0],"t":55}],"ix":2},"r":{"a":0,"k":0,"ix":10},"sa":{"a":0,"k":0},"o":{"a":0,"k":100,"ix":11}},"ef":[],"shapes":[{"ty":"gr","bm":0,"hd":false,"mn":"ADBE Vector Group","nm":"Rectangle 1","ix":1,"cix":2,"np":3,"it":[{"ty":"rc","bm":0,"hd":false,"mn":"ADBE Vector Shape - Rect","nm":"Rectangle Path 1","d":1,"p":{"a":0,"k":[0,0],"ix":3},"r":{"a":0,"k":20,"ix":4},"s":{"a":0,"k":[164,164],"ix":2}},{"ty":"fl","bm":0,"hd":false,"mn":"ADBE Vector Graphic - Fill","nm":"Fill 1","c":{"a":0,"k":[0.6078,0.6824,0.6706],"ix":4},"r":1,"o":{"a":0,"k":100,"ix":5}},{"ty":"tr","a":{"a":0,"k":[0,0],"ix":1},"s":{"a":0,"k":[100,100],"ix":3},"sk":{"a":0,"k":0,"ix":4},"p":{"a":0,"k":[-534,-230],"ix":2},"r":{"a":0,"k":0,"ix":6},"sa":{"a":0,"k":0,"ix":5},"o":{"a":0,"k":100,"ix":7}}]}],"ind":3},
      {"ty":1,"nm":"BG","sr":1,"st":0,"op":120,"ip":0,"hd":false,"ddd":0,"bm":0,"hasMask":false,"ao":0,"ks":{"a":{"a":0,"k":[960,540,0],"ix":1},"s":{"a":0,"k":[57.083,100,100],"ix":6},"sk":{"a":0,"k":0},"p":{"a":0,"k":[540,540,0],"ix":2},"r":{"a":0,"k":0,"ix":10},"sa":{"a":0,"k":0},"o":{"a":0,"k":100,"ix":11}},"ef":[],"sc":"#f7f7f7","sh":1080,"sw":1920,"ind":4}
    ],
    "v":"4.8.0","fr":24,"op":61,"ip":0,"assets":[]
  }), []);

  // Load the Lottie animation
  React.useEffect(() => {
    // Ensure lottie is available globally
    if (window.lottie && animationContainer.current) {
      try {
        const anim = window.lottie.loadAnimation({
          container: animationContainer.current,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData // Use inline data instead of path
        });
        
        anim.addEventListener('DOMLoaded', () => {
          setAnimationLoaded(true);
        });
        
        return () => {
          anim.destroy();
        };
      } catch (e) {
        console.error('Failed to load Lottie animation:', e);
      }
    }
  }, [animationData]);

  const wrapperStyle = {
    zIndex: 99,
    width: '300px',
    height: '300px',
    position: position === 'floating' ? 'absolute' : 'relative',
    margin: position === 'inline' || position === 'inline-centered' ? '0px' : '10px',
    left: position === 'floating' ? '50%' : undefined,
    top: position === 'floating' ? '50%' : undefined,
    transform: position === 'floating' ? 'translate(-50%, -50%)' : undefined,
  };

  // Fallback to Oval if Lottie doesn't load
  if (!animationLoaded && !window.lottie) {
    return (
      <Oval
        height="40"
        width="80"
        color="#244C5A"
        ariaLabel="oval-loading"
        wrapperStyle={wrapperStyle}
        wrapperClass={cls('loading', position, className)}
      />
    );
  }

  return (
    <div 
      ref={animationContainer}
      style={wrapperStyle} 
      className={cls('loading', position, className)}
      aria-label="Loading"
      role="status"
      aria-live="polite"
    />
  );
}
